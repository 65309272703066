@import "@/styles/variables.scss";
.user-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: $app-bg;

  &__left {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100vh;
    background: white;

    @mixin layoutMargin {
      margin-right: 90px;
      margin-left: auto;

      @media (max-width: 1540px) {
        margin-right: auto;
      }
    }

    .form-area {
      @include layoutMargin();

      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      width: 22rem;

      .login-form {
        label.ant-form-item-required {
          width: 100%;
        }
      }

      .form-bar {
        padding: 18px 0;

        .title {
          margin-bottom: 4px;
          color: #495057;
          font-weight: bold;
          font-size: 1.2rem;
        }

        .desc {
          color: $secondary;
          font-size: 0.8rem;
        }
      }

      .ant-form-item-label {
        padding-bottom: 5px;

        .ant-form-item-required {
          height: auto;
        }
      }
    }

    .footer {
      @include layoutMargin();

      padding: 20px 0;
      color: $secondary;
    }
  }

  &__right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .slick-dots {
      li {
        button {
          background: rgba(var(--primary), 1);
          opacity: 0.4;
        }

        &.slick-active button {
          background: rgba(var(--primary), 1);
        }
      }
    }
  }
}
