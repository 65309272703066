@import "@/styles/variables.scss";
.top-action-bar {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100vw;
  min-height: $top-action-bar-height;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);

  .actions {
    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 12px;
    transform: translateX(-50%);

    .action-item {
      min-width: 60px;
      text-align: center;
      cursor: pointer;
    }
  }
}
